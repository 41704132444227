import React from "react"

import WorkPageLayout from "./Components/WorkPageLayout"
import { Box, Flex } from "../../Components/Base"
import { WORK_ID, WORK } from "../../constant"

import Content from "./Components/Content"

const workId = WORK_ID.ART4D
const workData = WORK.find(w => w.key === workId)
const ROOT_IMAGE_PATH = `/assets/work/${workId}`

const HeaderSection = () => {
  return (
    <Box>
      <Content.Header>{workData.name}</Content.Header>
      <Content.SubHeader>role: {workData.role}</Content.SubHeader>
      <Flex flexDirection={["column", "row"]} justifyContent={"space-between"}>
        <Content.HeaderParagraph width={"70%"}>
          This project is the result of 2 months workshop arranged by the art4d
          magazine to design the booth at the Architect 2014 expo event. The
          objective is to study the different materials and system, then use
          that system to create the pavilion that could cover the large space of
          the booth and simultaneously create the stunning space for the
          activities beneath.
        </Content.HeaderParagraph>
        <Content.TextField
          textAlign={"right"}
          size={"small"}
          fields={[
            {
              field: "location",
              value: "Bangkok, Thailand",
            },
            { field: "program", value: "pavilion" },
            { field: "client", value: "art4d" },
            { field: "status", value: "completion 2014" },
          ]}
        />
      </Flex>
    </Box>
  )
}

const ContentSection = () => {
  return (
    <Content as={"article"}>
      <Content.Section>
        <Content.Image
          src={`/assets/work/${workId}/thumbnail.jpg`}
          height={"500px"}
          width={"100%"}
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Content.Section>

      <Content.Section title={"Process"} mb={5}>
        <Content.Paragraph>
          The workshop was separated into 3 phases, the concept design, design
          development, and construction phase. All processes was based on the
          practice of firstly asking a question, and then finding a solution
          related to architectural design in the digital age through different
          design processes. Instead of the perfection of final product, the main
          focus of this workshop lied in experimenting and exploring the
          behavioral components that were then logically integrated to create a
          system.
        </Content.Paragraph>
        <Content.Image
          src={`/assets/work/${workId}/process-01.jpg`}
          width={["100%", "50%"]}
          height={"300px"}
          caption={"design process based on prototypes and discussions"}
        />

        <Content.Image
          src={`/assets/work/${workId}/process-04.jpg`}
          width={["100%", "50%"]}
          height={"300px"}
          caption={"design process based on prototypes and discussions"}
        />
        <Content.Image
          src={`/assets/work/${workId}/process-02.jpg`}
          width={["100%", "50%"]}
          caption={"design process based on prototypes and discussions"}
        />
        <Content.Image
          src={`/assets/work/${workId}/process-03.jpg`}
          width={["100%", "50%"]}
          caption={"design process based on prototypes and discussions"}
        />
      </Content.Section>

      <Content.Section title={"Concept Design"}>
        <Content.Paragraph>
          Rather than using a conventional top-down approach, we tended to
          explore a bottom-up process that combined the study and research of
          all aspects and factors influencing the design from every dimension.
          We started to study the potential behaviors of different materials
          which could be developed as a structural member forlong-spanning
          structure, which could cover the large space.
        </Content.Paragraph>
      </Content.Section>

      <Content.Section title={"Design Development"}>
        <Content.Paragraph>
          The team experimented with the component to study its behavior in
          response to its properties such as the thicknesses and maximum member
          sizes which defined local component and aggregation logic of global
          geometry. Various geometries and aggregation logics have been explored
          in relation to different programme and spatial requirements.
        </Content.Paragraph>
        <Content.Image
          src={`/assets/work/${workId}/main-diagram.svg`}
          width={"100%"}
          caption={"diagram shows how the system works"}
        />
        <Content.Image
          src={`/assets/work/${workId}/development-01.jpg`}
          width={["100%", "50%"]}
          height={"300px"}
          caption={"possibilities of the system"}
        />
        <Content.Image
          src={`/assets/work/${workId}/development-02.jpg`}
          width={["100%", "50%"]}
          height={"300px"}
          caption={"possibilities of the system"}
        />
      </Content.Section>

      <Content.Section title={"Construction"}>
        <Content.Paragraph>
          After experiments of the system, the team finally designed and
          conclude the materials for the final prototype. The team had conducted
          the comprehensive analogue experiment on material and aggregation
          strategy based on various structural performance and spatial
          qualities. Finally, due to many constraints including time and budget,
          the plywood and 2-mm PP sheet were selected as the main material and
          the simple form of triangular dome was chosen to be the final
          prototype.
        </Content.Paragraph>
        <Content.Image
          src={`/assets/work/${workId}/prototype-01.jpg`}
          width={["100%", "50%"]}
          caption={"1:5 prototype"}
        />
        <Content.Image
          src={`/assets/work/${workId}/prototype-03.jpg`}
          width={["100%", "50%"]}
          caption={"1:1 prototype"}
        />
        <Content.Image
          src={`/assets/work/${workId}/construction-01.jpg`}
          width={"100%"}
          caption={"construction"}
        />
        {/* <Content.Image
        src={`/assets/work/${workId}/construction-02.jpg`}
        width={"100%"}
        caption={"construction"}
      /> */}
      </Content.Section>

      <Content.Section mb={5} title={"Final Product"}>
        <Content.Image
          src={`/assets/work/${workId}/final-02.jpg`}
          width={"100%"}
          caption={"final product"}
        />
        <Content.Image
          src={`/assets/work/${workId}/final-01.jpg`}
          width={"100%"}
          caption={"final product"}
        />
        <Content.Image
          src={`/assets/work/${workId}/main-final-drawing.svg`}
          width={"100%"}
          caption={"final drawing"}
        />
      </Content.Section>
      {/* <Content.Image
        src={`/assets/work/${workId}/final-03.jpg`}
        width={"100%"}
        caption={"final product"}
      /> */}
      <Content.Section flexDirection={"column"}>
        <Content.TextField
          fields={[
            {
              field: "Participants",
              value: [
                "Tadpol Rachatasumrit",
                "Thanaphat Sangkharom",
                "Thanatcha Tangamonsiri",
                "Thanisorn Devapalin",
                "Teejutha Chomparn",
                "Prawit Kittichantira",
                "Panisa Jermhunsa",
                "Phiraphat Sintopnumchai",
                "Pises Isarangkool na Ayuthaya",
                "Somrudee Somsiriwattana ",
                "Sunida Trevanich",
                "Sirorat Sitthitham",
                "Bodin Thawansakvudhi",
              ],
            },
          ]}
        />
        <Content.TextField
          fields={[
            {
              field: "Moderators | Advisors",
              value: [
                "Kanop Mangklapruk",
                "Karjvit Rirermvanich",
                "Theerapat Jirathiyut",
                "Atta Ponsumalee",
                // "Asst. Prof Rachaporn Choochuey",
                // "Sorawit Klaimark",
                // "Chuti Srisnguanvilas",
                // "Sukhumarn Thamwiset",
              ],
            },
          ]}
        />
        <Content.TextField
          fields={[
            {
              field: "Construction supervisor",
              value: ["Prasit Rirermvanich"],
            },
          ]}
        />
      </Content.Section>
    </Content>
  )
}
const Work = () => {
  return (
    <WorkPageLayout
      header={<HeaderSection />}
      fullWidthHeader={true}
      content={<ContentSection />}
      currentWork={workId}
    />
  )
}

export default Work
